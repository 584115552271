<template>
  <div>
    <el-dialog v-model="visible" @open="onOpen" @close="onClose" title="统计图">
      <el-card shadow="always">
        <el-row :gutter="5">
          <div class="block" style="margin-right: 1%">
            <span class="demonstration">选择污染物 </span>
            <el-select
              @change="handlePollutionNameChange"
              size="mini"
              v-model="select.PollutionCode"
              placeholder="污染物"
            >
              <el-option
                v-for="item in options.PollutionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="block" style="margin-right: 1%">
            <span class="demonstration">日期范围 </span>
            <el-date-picker
              @change="handleDateChange"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            >
            </el-date-picker>
          </div>
        </el-row>
      </el-card>
      <el-card>
        <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
        <div id="main" style="width: 100%; height: 400px"></div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getChartsData } from "@/api/charts";
import { getPollutionOptions } from "@/api/pollution";

let myChart;
export default {
  name: "ChartsLine",
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      date: [
        new Date(new Date(new Date().toLocaleDateString()).getTime()),
        new Date(),
      ],
      options: {
        PollutionOptions: [],
      },
      select: {
        PollutionCode: "",
      },
      option: {
        name: "",
        unit: "",
        data: {
          categoryData: [],
          valueData: [],
        },
      },
    };
  },
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {
      setTimeout(() => {
        this.getOptions();
      }, 1);
    },
    onClose() {
      // 销毁
      try {
        myChart.dispose();
      } catch (e) {
        console.log(e);
      }
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    // 污染物选择
    handlePollutionNameChange(val) {
      console.log(val);
      this.select.PollutionCode = val;
      try {
        // 销毁
        myChart.dispose();
      } catch (e) {
        console.log(e);
      }
      // 获取统计图数据
      this.getChartsData();
    },
    // 日期范围选择
    handleDateChange(val) {
      this.date[0] = new Date(val[0]);
      this.date[1] = new Date(val[1]);
      // 销毁
      try {
        myChart.dispose();
      } catch (e) {
        console.log(e);
      }
      // 获取统计图数据
      this.getChartsData();
    },
    // 获取污染物options
    getOptions() {
      const monitoryPointName = this.$route.query.monitoryPointName;
      const params = {
        monitoryPointName: monitoryPointName,
      };
      getPollutionOptions(params).then((result) => {
        this.options.PollutionOptions = result.data;
        console.log(JSON.stringify(result.data));
        // try {
        //   this.select.PollutionCode = result.data[0]['value']
        // } catch (e) {
        //   console.log(e)
        // }
        // 同步获取数据
        this.getChartsData();
      });
    },
    // 获取统计图数据
    getChartsData() {
      const pollutionCode = this.select.PollutionCode;
      if (pollutionCode === "" || pollutionCode === undefined) {
        return;
      }
      const monitoryPointName = this.$route.query.monitoryPointName;
      const beginDate = new Date(this.date[0]).getTime();
      const endDate = new Date(this.date[1]).getTime();
      const params = {
        monitoryPointName: monitoryPointName,
        pollutionCode: pollutionCode,
        beginDate: beginDate,
        endDate: endDate,
      };
      getChartsData(params)
        .then((result) => {
          this.option.data.categoryData = result.data.data.categoryData;
          this.option.data.valueData = result.data.data.valueData;
          this.option.name = result.data.name;
          this.option.unit = result.data.unit;
          this.handleCharts();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 渲染统计图
    handleCharts() {
      // 基于准备好的dom，初始化echarts实例
      myChart = echarts.init(document.getElementById("main"));

      const option = {
        title: {
          text: this.option.unit,
          left: 10,
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          bottom: 90,
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        xAxis: {
          data: this.option.data.categoryData,
          silent: false,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          splitArea: {
            show: false,
          },
        },
        series: [
          {
            name: this.option.name,
            type: "bar",
            data: this.option.data.valueData,
            // Set `large` for large data amount
            large: true,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped></style>
