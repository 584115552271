<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 数据管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>数据查看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-button @click="handleGoBack" plain icon="el-icon-back" size="mini">
          返回上一页</el-button
        >
        <el-button
          @click="handleOpenCharts"
          plain
          icon="el-icon-download"
          size="mini"
          type="danger"
        >
          图表
        </el-button>
        <el-button
          @click="handleExport"
          plain
          type="warning"
          icon="el-icon-download"
          size="mini"
        >
          导出
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">日期范围 </span>
          <el-date-picker
            @change="handleDateChange"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
          >
          </el-date-picker>
        </div>
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择监控点 </span>
          <el-select
            @change="handleMonitoryPointChange"
            size="mini"
            v-model="select.monitoryPointName"
            placeholder="监控点"
          >
            <el-option
              v-for="item in options.monitoryPointOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">数据类型 </span>
          <el-select
            @change="handleDataTypeChange"
            size="mini"
            v-model="select.dataType"
            placeholder="全部类型"
          >
            <el-option
              v-for="item in options.dataTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block" v-show="exceedBoolean">
          <span class="demonstration">是否超标 </span>
          <el-select
            @change="handleExceedChange"
            size="mini"
            v-model="select.dataStatus"
            placeholder="全部数据"
          >
            <el-option
              v-for="item in options.dataStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column fixed type="index" :index="1"> </el-table-column>
        <el-table-column fixed prop="dataTime" label="数据时间" width="160px">
        </el-table-column>
        <!--一级表头-->
        <el-table-column
          :key="item.column.label"
          v-for="item in colHead"
          :label="item.column.label"
          :prop="item.column.prop"
        >
          <!--二级表头-->
          <el-table-column
            :key="second.label"
            v-for="second in item.secondColumns"
            :label="second.label"
            :prop="second.prop"
          >
            <template
              #header
              v-if="
                second.prop === 'w00000-Max' ||
                second.prop === 'w00000-Min' ||
                second.prop === 'w00000-Avg'
              "
            >
              {{ second.label }}
              <el-button
                type="text"
                size="mini"
                @click="handleSwitchUnit(second.label)"
              >
                切换
              </el-button>
            </template>
            <template #default="scope">
              {{ scope.row[second.prop] }}
              <br />
              <el-tag
                v-if="
                  list[scope.$index]['datagramFlag'] !== null &&
                  list[scope.$index]['datagramFlag'][second.prop] === 'F' &&
                  second.prop.indexOf('Avg')!==-1
                "
                size="mini"
                type="danger"
              >
                  <span v-if="scope.row[second.prop] == 0">
                      呆滞
                  </span>
                  <span v-else>
                    超标
                  </span>
              </el-tag>
              <el-tag
                v-if="
                  list[scope.$index]['datagramFlag'] !== null &&
                  list[scope.$index]['stagnateFlag'][second.prop] === 1 &&
                  monitoryPointType !== '气类' &&
                  select.dataType === 'hour'
                "
                size="mini"
                type="info"
              >
                呆滞
              </el-tag>
            </template>
          </el-table-column>
          <template
            #header
            v-if="
              item.secondColumns === null && item.column.prop === 'w00000-Rtd'
            "
          >
            {{ item.column.label }}
            <el-button
              type="text"
              size="mini"
              @click="handleSwitchUnit(item.column.label)"
            >
              切换
            </el-button>
          </template>
          <!--          无二级表头-->
          <template #default="scope" v-if="item.secondColumns === null">
            {{ scope.row[item.column.prop] }}
            <br />
            <el-tag
              v-if="
                list[scope.$index]['datagramFlag'] !== null &&
                list[scope.$index]['datagramFlag'][item.column.prop] === 'F'
              "
              size="mini"
              type="danger"
            >
              <span v-if="scope.row[item.column.prop] == 0">
                呆滞
              </span>
              <span v-else>
                超标
              </span>
            </el-tag>
            <el-tag
              v-if="
                list[scope.$index]['datagramFlag'] !== null &&
                list[scope.$index]['stagnateFlag'][item.column.prop] === 1 &&
                monitoryPointType !== '气类' &&
                select.dataType === 'hour'
              "
              size="mini"
              type="info"
            >
              呆滞
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <charts-line
      :dialog-visible="dialogVisibleCharts"
      @updateVisible="closeCharts"
    >
    </charts-line>
  </div>
</template>

<script>
// import router from "@/router";
import { getMonitoryPointOptionsByCompanyName } from "@/api/monitory-point";
import { getTable } from "@/api/data-detail";
import ChartsLine from "@/views/data/check/charts/index";
import { exportCheckDetail } from "@/api/export";
import { getDayStartAndEnd, getMonthStartAndEnd } from "../../../../utils/Date";

export default {
  name: "DataDetail",
  components: { ChartsLine },
  data() {
    return {
      dialogVisibleCharts: false,
      list: [],
      monitoryPointType: "未设置",
      secondColumns: undefined,
      date: [
        new Date(new Date(new Date().toLocaleDateString()).getTime()),
        new Date(),
      ],
      options: {
        monitoryPointOptions: [],
        dataTypeOptions: [
          {
            value: "real",
            label: "实时数据",
          },
          {
            value: "minute",
            label: "分钟数据",
          },
          {
            value: "hour",
            label: "小时数据",
          },
          {
            value: "day",
            label: "日数据",
          },
          {
            value: "month",
            label: "月数据",
          },
          {
            value: "year",
            label: "年数据",
          },
        ],
        dataStatusOptions: [
          {
            value: "",
            label: "全部数据",
          },
          {
            value: "normal",
            label: "正常数据",
          },
          {
            value: "exceed",
            label: "超标数据",
          },
        ],
      },
      select: {
        monitoryPointName: "",
        dataType: "real",
        dataStatus: "",
      },
      exceedBoolean: true,
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      colHead: [],
    };
  },
  created() {
    this.select.monitoryPointName = this.$route.query.monitoryPointName;
    let latestAlarmTime = this.$route.query.latestAlarmTime;
    if (latestAlarmTime !== undefined) {
      latestAlarmTime = parseInt(latestAlarmTime);
      this.date[0] = new Date(latestAlarmTime);
      this.date[1] = new Date(latestAlarmTime);
    }
    this.getOptions();
    this.getList();
  },
  methods: {
    handleSetSecondColumns(secondColumns) {
      console.log("次数");
      this.secondColumns = secondColumns;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleGoBack() {
      // router.go(-1)
      history.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    handleExport() {
      const params = {
        beginDateTime: new Date(this.date[0]).getTime(),
        endDateTime: new Date(this.date[1]).getTime(),
        monitoryPointName: this.$route.query.monitoryPointName,
        dataType: this.select.dataType,
        total: this.pagination.total,
      };
      exportCheckDetail(params);
    },
    handleDateChange(val) {
      this.date[0] = val[0];
      this.date[1] = val[1];
      // if (this.select.dataType === 'month' || this.select.dataType === 'quarter') {
      //   this.date[1] = this.date[0]
      // }
      this.getList();
    },
    handleMonitoryPointChange(val) {
      console.log(val);
      this.select.monitoryPointName = val;
      this.getList();
    },
    handleDataTypeChange(val) {
      var monthStartAndEnd = getMonthStartAndEnd();
      var dayStartAndEnd = getDayStartAndEnd();
      console.log(val);
      if (val === "year") {
        this.select.dataType = "month";
      } else if (val === "month") {
        this.select.dataType = "day";
        this.date[0] = monthStartAndEnd[0];
        this.date[1] = monthStartAndEnd[1];
      } else if (val === "day") {
        this.select.dataType = "hour";
        this.date[0] = dayStartAndEnd[0];
        this.date[1] = dayStartAndEnd[1];
      } else {
        this.select.dataType = val;
      }
      // if (this.select.dataType === 'month' || this.select.dataType === 'quarter') {
      //   this.date[1] = this.date[0]
      // }
      // 设置时间
      this.exceedBoolean = val === "real";
      this.getList();
      this.select.dataType = val;
    },
    handleExceedChange(val) {
      console.log(val);
      this.select.dataStatus = val;
      this.getList();
    }, // 打开图表
    handleOpenCharts() {
      this.dialogVisibleCharts = true;
    }, // 关闭图表
    closeCharts() {
      this.dialogVisibleCharts = false;
    }, // 获取监控点options
    getOptions() {
      const params = {
        companyName: this.$route.query.companyName,
      };
      getMonitoryPointOptionsByCompanyName(params).then((result) => {
        this.options.monitoryPointOptions = result.data;
        this.select.monitoryPointName = this.$route.query.monitoryPointName;
      });
    },
    getList() {
      const param = {
        monitoryPointName: this.select.monitoryPointName,
        dataType: this.select.dataType,
        dataStatus: this.select.dataStatus,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        beginDate: new Date(this.date[0]).getTime(),
        endDate: new Date(this.date[1]).getTime(),
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        const data = result.data;
        this.pagination.total = data.commonPage.total;
        this.pagination.pageSize = data.commonPage.pageSize;
        this.pagination.currentPage = data.commonPage.currentPage + 1;
        const list = [];
        data.commonPage.list.forEach((o) => {
          list.push(o.datagramData);
        });
        this.tableData = list;
        this.colHead = data.colHead;
        this.monitoryPointType = data.monitoryPointType;
        console.log(this.monitoryPointType);
        this.list = data.commonPage.list;
      });
    },
    handleSwitchUnit(label) {
      console.log("label:", label);
      console.log("colHead:", JSON.stringify(this.colHead));
      if (label.indexOf("h") !== -1) {
        this.colHead.forEach((value) => {
          // if (value.column.prop === 'w00000-Rtd') {
          let prefix;
          if (
            value.column.label.indexOf("污水") !== -1 &&
            value.column.label.indexOf("流量") === -1
          ) {
            // 更改label
            if (this.select.dataType === "real") {
              value.column.label = "污水(L/s)";
            } else {
              value.secondColumns.forEach((t) => {
                if (t.label === label) {
                  prefix = t.label.substring(0, 3);
                  t.label = t.label.substring(0, 3) + "(L/s)";
                }
              });
            }
            this.convertData("秒", prefix);
          }
        });
      } else {
        this.colHead.forEach((value) => {
          // if (value.column.prop === 'w00000-Rtd') {
          let prefix;
          if (
            value.column.label.indexOf("污水") !== -1 &&
            value.column.label.indexOf("流量") === -1
          ) {
            // 更改label
            if (this.select.dataType === "real") {
              value.column.label = "污水(m3/h)";
            } else {
              value.secondColumns.forEach((t) => {
                if (t.label === label) {
                  prefix = t.label.substring(0, 3);
                  t.label = t.label.substring(0, 3) + "(m3/h)";
                }
              });
            }
            this.convertData("小时", prefix);
          }
        });
      }
    },
    convertData(unit, prefix) {
      if (unit === "小时") {
        if (this.select.dataType === "real") {
          this.tableData.forEach((value) => {
            value["w00000-Rtd"] = (value["w00000-Rtd"] * 3.6).toFixed(4);
          });
        } else {
          this.tableData.forEach((value) => {
            if (prefix === "最大值") {
              value["w00000-Max"] = (value["w00000-Max"] * 3.6).toFixed(4);
            } else if (prefix === "最小值") {
              value["w00000-Min"] = (value["w00000-Min"] * 3.6).toFixed(4);
            } else if (prefix === "平均值") {
              value["w00000-Avg"] = (value["w00000-Avg"] * 3.6).toFixed(4);
            }
            // value['w00000-Cou'] = (value['w00000-Cou'] / (1000 / 3600)).toFixed(4)
          });
        }
      } else {
        if (this.select.dataType === "real") {
          this.tableData.forEach((value) => {
            value["w00000-Rtd"] = (value["w00000-Rtd"] * (1000 / 3600)).toFixed(
              4
            );
          });
        } else {
          this.tableData.forEach((value) => {
            if (prefix === "最大值") {
              value["w00000-Max"] = (
                value["w00000-Max"] *
                (1000 / 3600)
              ).toFixed(4);
            } else if (prefix === "最小值") {
              value["w00000-Min"] = (
                value["w00000-Min"] *
                (1000 / 3600)
              ).toFixed(4);
            } else if (prefix === "平均值") {
              value["w00000-Avg"] = (
                value["w00000-Avg"] *
                (1000 / 3600)
              ).toFixed(4);
            }
            // value['w00000-Cou'] = (value['w00000-Cou'] * (1000 / 3600)).toFixed(4);
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.el-tag {
  margin-left: 2px;
}
</style>
